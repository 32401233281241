.projects {
  text-align: center;
}

.projects__slider {
  height: calc(100vh - 180px);
  overflow: hidden;
}

/* Styles pour les grands écrans */
.project__card {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border: 1px solid transparent;
  border-radius: 10px;
  box-shadow: 0 4px 6px transparent;
  margin-right: 6%;

}

/* .project__card .Web{
  display: flex;
 flex-direction: column;
}

.project__card .Mobile{
  display: flex;
  flex-direction: row;
 } */





.project__content {
  margin-left: 6%;
  flex: 1;
  height: 100%; 
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: flex-start; 
  position: relative; 
  padding-right: 3rem;
}


.project__title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #333;
}


.project__description {
  font-size: 1rem;
  color: #666;
  font-size: var(--small-font-size);
  text-align: left;
}

.project__techno {
  font-size: 0.9rem;
  color: #555;
  margin-top: 0.5rem;
}

.project__image {
  flex: 0 0 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: transparent;
  overflow: hidden;
}



.project__img {

  /* width: 100%; */
 
  /* height: auto; */
  object-fit: contain;
  overflow: visible;
}



img.project__img {
  border: 1px solid grey;
  border-radius: 10px;
}



.full_screen_text {
  color: #fff;
  font-size: var(--smaller-font-size);
}

.ambany {
  display: none;
}


.Mobile {
  height: 100%;
}

.Web {

 height: auto;
}



/* Disposition en colonne pour les petits écrans */
@media screen and (max-width: 768px) {
  .projects__slider {
    height: inherit;
    overflow: hidden;
    width: calc(100%);
  }
  .project__card {
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }

  .ambony {
    display: none;
  }

  .project__content {
    width: 100%;
    /* background-color: yellow; */
  
  }
  .project__image {
    margin: 0;
    text-align: center; 
  }
  .project__image .Mobile{
   width: calc(100% - 150px);
  }

  .Mobile {
     width: calc(100% - 200px);
     height: 100%;
  }

.Web {
    width: calc(100% - 40px);
    margin-left: auto;
    margin-block-end: auto;
    height: auto;
 }

  .project__content {
    padding: 1rem 0;
    text-align: center; 
  }

  .project__title , .project__techno , .project__description {
    width: 100%;
    text-align: center; /* Centre le texte */
    /* background-color: yellow; */
  }

  .project__image {
    margin-top: 1rem; /* Ajoute un espace entre les deux sections */
  }

  .view-button {
    margin: 1rem auto 0; /* Haut : 1rem, Gauche/Droite : auto, Bas : 0 */
  }
}